import React, { Component, Fragment } from 'react';
import { HashRouter as Router, Route } from "react-router-dom";
import { enquireScreen } from 'enquire-js';
import Header from './Home/Nav0';
import Footer from './Home/Footer1';
import Home from './Home';
import Gallery from './pages/Gallery';
import Zhuzhu from './pages/Zhuzhu';
import ToolsCollection from './pages/Tools/Collection';
import TimeCounter from './pages/Tools/TimeCounter';
import Crypto from './pages/Tools/Crypto';
import Dashboard from './pages/Dashboard';
import Test from './pages/Test';
import {
  Nav00DataSource,
  Footer11DataSource,
} from './Home/data.source.js';
import "./App.css"

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
    };
  }
  componentDidMount() {
    // 适配手机屏幕;
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
  }
  render() {
    return (
      <Router>
        <Fragment>
          <Header dataSource={Nav00DataSource} isMobile={this.state.isMobile} />
          <Route exact path="/" component={Home} />
          <Route path="/gallery" component={Gallery} />
          <Route path="/c_and_z" component={Zhuzhu} />
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/test" component={Test} />
          <Route path="/tools" component={ToolsCollection} />
          <Route path="/tools/timecounter" component={TimeCounter} />
          <Route path="/tools/crypto" component={Crypto} />
          <Footer dataSource={Footer11DataSource} isMobile={this.state.isMobile} />
        </Fragment>
      </Router>
    );
  }
}

export default App;
