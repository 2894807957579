import React from 'react';
import { Input, Row, message, Card, Button } from 'antd';
import {
  UnlockOutlined,
  LockOutlined,
} from '@ant-design/icons';
import './Crypto.less'

const { TextArea } = Input;


export default class Crypto extends React.Component {
  constructor() {
    super();
    this.state = {
      input1: '',
      input2: '',
      output: ''
    }
  }

  render() {
    var CryptoJS = require("crypto-js");
    const handleInput1Change = (event) => {
      this.setState({ input1: event.target.value })
      // console.log(event.target.value)
    }
    const handleInput2Change = (event) => {
      this.setState({ input2: event.target.value })
      // console.log(event.target.value)
    }
    const handleClearInput1 = (event) => {
      this.setState({ input1: "" })
      // console.log(event.target.value)
    }
    const handleClearInput2 = (event) => {
      this.setState({ input2: "" })
      // console.log(event.target.value)
    }
    const handleEncrypt = () => {
      // console.log(this.state.input1, this.state.input2)
      var ciphertext = CryptoJS.AES.encrypt(this.state.input1, this.state.input2).toString();
      this.setState({ output: ciphertext })
    }
    const handleDecrypt = () => {
      // console.log(this.state.input1, this.state.input2)

      try {
        var bytes = CryptoJS.AES.decrypt(this.state.input1, this.state.input2);
        var originalText = bytes.toString(CryptoJS.enc.Utf8);
        this.setState({ output: originalText })
      } catch (e) {
        console.log(e)
        message.error('Decrypt Error!');
      }
    }
    return (
      <div className='bg'>
        {/* <span className='timecounter-row-title'>日期计算</span> */}
        <Card title="AES加密解密" bordered={false} className="timecounter-row">
          <Row className='crypto-row'>
            <span>原文或密文</span>
            <TextArea rows={4} onChange={handleInput1Change} value={this.state.input1} />
          </Row>
          <Row className='crypto-row'>
            <span>密钥</span>
            <TextArea rows={2} onChange={handleInput2Change} value={this.state.input2} />
          </Row>
          <Row className='crypto-row'>
            <Button onClick={handleEncrypt} icon={<LockOutlined />} type="primary" className="tool-crypto-btn">加密</Button>
            <Button onClick={handleDecrypt} icon={<UnlockOutlined />} type="primary" className="tool-crypto-btn">解密</Button>
            <Button onClick={handleClearInput1} type="primary" danger className="tool-crypto-btn">清除输入</Button>
            <Button onClick={handleClearInput2} type="primary" danger className="tool-crypto-btn">清除密钥</Button>
          </Row>
          <Row className='crypto-row'>
            <span>输出</span>
            <TextArea rows={4} value={this.state.output} />
          </Row>
        </Card>
      </div>
    )

  }
}
