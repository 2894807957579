import React from 'react';
import { DatePicker, Col, Row, InputNumber, Card } from 'antd';
import './TimeCounter.less'
import moment from 'moment';

const { RangePicker } = DatePicker;


export default class TimeCounter extends React.Component {

  constructor() {
    super();
    this.state = {
      section1_date: moment(),
      section1_day: 0,
      section1_hour: 0,
      section1_minute: 0,
      section1_second: 0,
      section1_result: moment(),
      section2_day: 0,
      section2_hour: 0,
      section2_minute: 0,
      section2_second: 0
    }
  }

  render() {
    const handleSection1DateChange = (date, dateString) => {
      console.log(date, dateString);
      this.setState({ section1_date: date })
    };
    const handleSection1InputChange = (type, num) => {
      // console.log(num,type)
      if (type === 'd') {
        this.setState({ section1_day: num }, () => { handleSection1Click() })
      }
      else if (type === 'h') {
        this.setState({ section1_hour: num }, () => { handleSection1Click() })
      }
      else if (type === 'm') {
        this.setState({ section1_minute: num }, () => { handleSection1Click() })
      }
      else if (type === 's') {
        this.setState({ section1_second: num }, () => { handleSection1Click() })
      }
      // handleSection1Click()
    }
    // const handleSection1InputChange = (num) => {
    //   console.log(num)
    //   this.setState({ section1_input: num })
    // }
    // const handleChange = (e) => {
    //   const { value: inputValue } = e.target;
    //   const reg = /^-?\d*(\.\d*)?$/;

    //   if (reg.test(inputValue) || inputValue === '' || inputValue === '-') {
    //     handleSection1InputChange(inputValue);
    //   }
    // }; // '.' at the end or only '-' in the input box.
    const handleSection1Click = () => {
      var date_start = this.state.section1_date
      console.log(date_start, this.state.section1_day)
      this.setState({ section1_result: moment(date_start).add({ days: this.state.section1_day, hours: this.state.section1_hour, minutes: this.state.section1_minute, seconds: this.state.section1_second }) })
      // console.log(this.state.section1_result)
    }

    const handleSection2Change = (date, dateString) => {
      var diff = date[1].diff(date[0]) / 1000

      console.log(date, diff)
      var d = Math.floor(diff / (3600 * 24))
      diff -= d * 3600 * 24
      var h = Math.floor(diff / (3600))
      diff -= h * 3600
      var m = Math.floor(diff / (60))
      diff -= m * 60

      this.setState({
        section2_day: d,
        section2_hour: h,
        section2_minute: m,
        section2_second: diff
      })
    }

    return (
      <div className='bg'>
        {/* <span className='timecounter-row-title'>日期计算</span> */}
        <Card title="日期计算" bordered={false} className="timecounter-row">

          <Row gutter={[16, 24]}>
            <Col span={5}>
              <DatePicker onChange={handleSection1DateChange} defaultValue={this.state.section1_date} showTime />
            </Col>
            {/* <Col span={1}>经过</Col> */}
            <Col span={14}>
              {/* <Input
              value={this.state.section1_input}
              onChange={handleChange}
              placeholder="Input a number"
              maxLength={25}
            /> */}
              <InputNumber value={this.state.section1_day} onChange={handleSection1InputChange.bind(this, 'd')} /> 天
              <InputNumber value={this.state.section1_hour} onChange={handleSection1InputChange.bind(this, 'h')} /> 时
              <InputNumber value={this.state.section1_minute} onChange={handleSection1InputChange.bind(this, 'm')} /> 分
              <InputNumber value={this.state.section1_second} onChange={handleSection1InputChange.bind(this, 's')} /> 秒

            </Col>
            {/* <Col span={1}>是</Col> */}
            {/* <Col span={2}>
            <Button type="primary" onClick={handleSection1Click}>计算</Button>
          </Col> */}
            <Col span={5}>
              <DatePicker disabled value={this.state.section1_result} showTime />
            </Col>
          </Row>
        </Card>
        {/* <span className='timecounter-row-title'>相距计算</span> */}
        <Card title="相距计算" bordered={false} className="timecounter-row">

          <Row gutter={[16, 24]}>
            <Col span={12}>
              <RangePicker showTime onChange={handleSection2Change} />
            </Col>
            {/* <Col span={4}>是</Col> */}
            <Col span={12}>
              {this.state.section2_day}天{this.state.section2_hour}时{this.state.section2_minute}分{this.state.section2_second}秒
            </Col>
          </Row>
        </Card>
        <Card title="时间戳转日期" bordered={false} className="timecounter-row">

        </Card>
        <Card title="日期转时间戳" bordered={false} className="timecounter-row">
        </Card>
      </div>
    )

  }
}
