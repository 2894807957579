import React from 'react';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';
import { Carousel as AntCarousel, Row, Col } from 'antd';
import TweenOne from 'rc-tween-one';
import Children from 'rc-tween-one/lib/plugin/ChildrenPlugin';

TweenOne.plugins.push(Children);

class Feature6 extends React.PureComponent {
  constructor(props) {
    super(props);
    this.carouselRef = React.createRef();
    const start_timestamp = 1595174400000;
    const passed_day = Math.trunc((Date.now() - start_timestamp) / 1000 / 3600 / 24);
    const dataSource = {
      wrapper: { className: 'home-page-wrapper feature6-wrapper' },
      OverPack: { className: 'home-page feature6', playScale: 0.3 },
      Carousel: {
        className: 'feature6-content',
        dots: false,
        wrapper: { className: 'feature6-content-wrapper' },
        titleWrapper: {
          className: 'feature6-title-wrapper l7k64qwsd9-editor_css',
          barWrapper: {
            className: 'feature6-title-bar-wrapper',
            children: { className: 'feature6-title-bar' },
            // children: { className: 'feature6-title-bar' },
          },
          title: { className: 'feature6-title' },
        },
        children: [
          {
            // title: { className: 'feature6-title-text', children: '关于网站' },
            title: { className: 'feature6-title-text title-h1', children: '关于网站' },
            className: 'feature6-item',
            name: 'block0',
            children: [
              {
                md: 8,
                xs: 24,
                className: 'feature6-number-wrapper',
                name: 'child0',
                number: {
                  className: 'feature6-number',
                  id: '',
                  unit: { className: 'feature6-unit', children: '天' },
                  toText: true,
                  children: passed_day.toString(),
                },
                children: { className: 'feature6-text', children: '正常运行' },
              },
              {
                md: 8,
                xs: 24,
                className: 'feature6-number-wrapper',
                name: 'child1',
                number: {
                  className: 'feature6-number ',
                  id: "site-counter",
                  unit: { className: 'feature6-unit', children: '次' },
                  toText: true,
                  children: '10',
                },
                children: { className: 'feature6-text', children: '网站访问量' },
              },
              {
                md: 8,
                xs: 24,
                className: 'feature6-number-wrapper',
                name: 'child2',
                number: {
                  id: '',
                  className: 'feature6-number',
                  unit: { className: 'feature6-unit', children: '亿' },
                  toText: true,
                  children: '38',
                },
                children: { className: 'feature6-text', children: '版本迭代数量' },
              },
            ],
          },
        ],
      },
    };



    this.state = {
      dataSource: dataSource
    }
    setInterval(() => {
      var bszPV = document.getElementById('busuanzi_value_site_pv')
      var bszUV = document.getElementById('busuanzi_value_site_uv')
      var pv = (parseInt(bszPV.textContent) + 500).toString();
      var uv = (parseInt(bszUV.textContent) + 500).toString();
      const start_timestamp = 1595174400000;
      const passed_day = Math.trunc((Date.now() - start_timestamp) / 1000 / 3600 / 24);
      const dataSource = {
        wrapper: { className: 'home-page-wrapper feature6-wrapper' },
        OverPack: { className: 'home-page feature6', playScale: 0.3 },
        Carousel: {
          className: 'feature6-content',
          dots: false,
          wrapper: { className: 'feature6-content-wrapper' },
          titleWrapper: {
            className: 'feature6-title-wrapper l7k64qwsd9-editor_css',
            barWrapper: {
              className: 'feature6-title-bar-wrapper',
              children: { className: 'feature6-title-bar' },
              // children: { className: 'feature6-title-bar' },
            },
            title: { className: 'feature6-title' },
          },
          children: [
            {
              // title: { className: 'feature6-title-text', children: '关于网站' },
              title: { className: 'feature6-title-text title-h1', children: '关于网站' },
              className: 'feature6-item',
              name: 'block0',
              children: [
                {
                  md: 8,
                  xs: 24,
                  className: 'feature6-number-wrapper',
                  name: 'child0',
                  number: {
                    className: 'feature6-number',
                    id: '',
                    unit: { className: 'feature6-unit', children: '天' },
                    toText: true,
                    children: passed_day.toString(),
                  },
                  children: { className: 'feature6-text', children: '正常运行' },
                },
                {
                  md: 8,
                  xs: 24,
                  className: 'feature6-number-wrapper',
                  name: 'child1',
                  number: {
                    className: 'feature6-number ',
                    id: "site-counter",
                    unit: { className: 'feature6-unit', children: '次' },
                    toText: true,
                    children: pv,
                  },
                  children: { className: 'feature6-text', children: '网站访问量' },
                },
                {
                  md: 8,
                  xs: 24,
                  className: 'feature6-number-wrapper',
                  name: 'child2',
                  number: {
                    id: '',
                    className: 'feature6-number',
                    unit: { className: 'feature6-unit', children: '人' },
                    toText: true,
                    children: uv,
                  },
                  children: { className: 'feature6-text', children: '网站访客量' },
                },
              ],
            },
          ],
        },
      };
      this.setState({ dataSource: dataSource })
    }, 1000)
  }

  onTitleClick = (_, i) => {
    const carouselRef = this.carouselRef.current.childRefs.carousel;
    carouselRef.goTo(i);
  };

  onBeforeChange = (_, newIndex) => {
    this.setState({
      current: newIndex,
    });
  };

  getChildrenToRender = (dataSource) => {
    const { current } = this.state;
    const { Carousel } = dataSource;
    const {
      titleWrapper,
      children: childWrapper,
      wrapper,
      ...carouselProps
    } = Carousel;

    const {
      barWrapper,
      title: titleChild,
      ...titleWrapperProps
    } = titleWrapper;
    const titleToRender = [];

    const childrenToRender = childWrapper.map((item, ii) => {
      const { title, children, ...itemProps } = item;
      titleToRender.push(
        <div
          {...title}
          key={ii.toString()}
          onClick={(e) => {
            this.onTitleClick(e, ii);
          }}
          className={
            ii === current ? `${title.className || ''} active` : title.className
          }
        >
          {title.children}
        </div>
      );
      const childrenItem = children.map(($item, i) => {
        const { number, children: child, ...childProps } = $item;
        const numberChild = number.children.replace(/[^0-9.-]/g, '');
        const { unit, toText, ...numberProps } = number;
        return (
          <Col {...childProps} key={i.toString()}>
            <TweenOne
              {...numberProps}
              animation={{
                Children: {
                  value: parseFloat(numberChild),
                  floatLength:
                    parseFloat(numberChild) -
                      Math.floor(parseFloat(numberChild)) >
                      0
                      ? 2
                      : 0,
                  formatMoney: true,
                },
                duration: 1000,
                delay: 300,
                ease: 'easeInOutCirc',
              }}
              component="span"
            >
              0
            </TweenOne>
            {unit && <span {...unit}>{unit.children}</span>}
            <p {...child}>{child.children}</p>
          </Col>
        );
      });
      return (
        <div key={ii.toString()}>
          <QueueAnim type="bottom" component={Row} {...itemProps}>
            {childrenItem}
          </QueueAnim>
        </div>
      );
    });

    const width = 100 / childrenToRender.length;
    return (
      <QueueAnim
        key="queue"
        leaveReverse
        type="bottom"
        delay={[0, 100]}
        {...wrapper}
        ref={this.carouselRef}
      >
        <div {...titleWrapperProps} key="title">
          <div {...titleChild}>
            {titleToRender}
            <div
              {...barWrapper}
              style={{
                width: `${width}%`,
                left: `${width * current}%`,
              }}
            >
              <em {...barWrapper.children} />
            </div>
          </div>
        </div>
        <AntCarousel
          {...carouselProps}
          key="carousel"
          infinite={false}
          beforeChange={this.onBeforeChange}
        >
          {childrenToRender}
        </AntCarousel>
      </QueueAnim>
    );
  };

  render() {
    // const { isMobile, ...props } = this.props;

    return (
      <div {...this.state.dataSource.wrapper}>
        <div>
          <OverPack {...this.state.dataSource.OverPack}>
            {this.getChildrenToRender(this.state.dataSource)}
          </OverPack>
        </div>
      </div>
    );
  }
}
export default Feature6;
