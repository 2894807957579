import React from 'react';
import axios from 'axios';
import { Row, Col } from 'antd';

const showLocation = (pos) => {
  console.log(pos)
}
const showError = (e) => { console.log(e) }
export default class Home extends React.Component {
  constructor() {
    super();
    this.state = {
      time: new Date().toLocaleTimeString()
    }
    setInterval(() => {
      this.setState({
        time: new Date().toLocaleTimeString()
      })
    }, 100)
    axios.get('https://devapi.qweather.com/v7/weather/now', { params: { key: '836a1476f2ec4e6895b2c4087e6c1bab', location: '101010100' } }).then(
      response => { console.log('成功了', response.data); },
      error => { console.log('失败了', error); }
    )
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        pos => showLocation(pos), // 成功函数
        e => showError(e), // 失败函数
        { timeout: 5000 } // PositionOptions参数 设置超时时间
      );
    } else {
      alert("浏览器不支持地理定位！");
    }
  }
  render() {
    // const onSearch = () => {
    // };
    return (
      <div className='dashboard-bg'>
        <Col className='dashboard-col'>
          <Row className='dashboard-time'>{this.state.time}</Row>
        </Col>
      </div>
    )
  }
}