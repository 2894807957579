import React from 'react';
// import axios from 'axios';

import logo from '../asserts/logo.png'
import p1 from '../asserts/camera.svg'
import p8 from '../asserts/block.svg'

export const Nav00DataSource = {
  wrapper: { className: 'header0 home-page-wrapper' },
  page: { className: 'home-page' },
  logo: {
    className: 'header0-logo',
    // children: 'https://os.alipayobjects.com/rmsportal/mlcYmsRilwraoAe.svg',
    children: logo,
  },
  Menu: {
    className: 'header0-menu',
    children: [
      { name: 'item0', a: { children: '主页', href: '/' } },
      { name: 'item1', a: { children: '导航二', href: '/abc' } },
      { name: 'item2', a: { children: '导航三', href: '' } },
      { name: 'item3', a: { children: '旧版主页', href: 'https://old.andisionzhang.com' } },
    ],
    items: [
      {
        label: (
          <a href="/" target="_blank" rel="noopener noreferrer">
            主页
          </a>
        ),
        key: 'item0'
      },
      {
        label: (
          <a href="https://old.andisionzhang.com" target="_blank" rel="noopener noreferrer">
            旧版主页
          </a>
        ),
        key: 'item1'
      },
    ],
  },
  mobileMenu: { className: 'header0-mobile-menu' },
};
export const Banner01DataSource = {
  wrapper: { className: 'banner0 l7k64zta5jn-editor_css' },
  textWrapper: { className: 'banner0-text-wrapper' },
  title: {
    className: 'banner0-title',
    children: logo,
    // children: 'https://zos.alipayobjects.com/rmsportal/HqnZZjBjWRbjyMr.png',
  },
  content: {
    className: 'banner0-content',
    children: '欢迎来到我的主页',
  },
  button: { className: 'banner0-button', children: 'Learn More' },
};
export const Content130DataSource = {
  OverPack: {
    className: 'home-page-wrapper content13-wrapper l7k65et6gy-editor_css',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      // {
      //   name: 'image',
      //   children:
      //     'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
      //   className: 'title-image',
      // },
      { name: 'title', children: '关于我', className: 'title-h1' },
      {
        name: 'content',
        children:
          'THU CST B.E. ',
        className: 'title-content',
      },
      {
        name: 'content2',
        children: 'XJBT No.2 Sch. 毕业生',
        className: 'title-content',
      },
      {
        name: 'content2',
        children: '计算机、音乐、ACGN、旅行……',
        className: 'title-content',
      },
      {
        name: 'content2',
        children: '',
        className: 'title-content',
      },
      {
        name: 'content2',
        children: '乐于交友，欢迎交流',
        className: 'title-content',
      },
      {
        name: 'content2',
        children: '🫶',
        className: 'title-content',
      },
    ],
  },
};

const start_timestamp = 1595174400000;
const passed_day = Math.trunc((Date.now() - start_timestamp) / 1000 / 3600 / 24);

export const Feature60DataSource = {
  wrapper: { className: 'home-page-wrapper feature6-wrapper' },
  OverPack: { className: 'home-page feature6', playScale: 0.3 },
  Carousel: {
    className: 'feature6-content',
    dots: false,
    wrapper: { className: 'feature6-content-wrapper' },
    titleWrapper: {
      className: 'feature6-title-wrapper l7k64qwsd9-editor_css',
      barWrapper: {
        className: 'feature6-title-bar-wrapper',
        children: { className: 'feature6-title-bar' },
        // children: { className: 'feature6-title-bar' },
      },
      title: { className: 'feature6-title' },
    },
    children: [
      {
        // title: { className: 'feature6-title-text', children: '关于网站' },
        title: { className: 'feature6-title-text title-h1', children: '关于网站' },
        className: 'feature6-item',
        name: 'block0',
        children: [
          {
            md: 8,
            xs: 24,
            className: 'feature6-number-wrapper',
            name: 'child0',
            number: {
              className: 'feature6-number',
              id: '',
              unit: { className: 'feature6-unit', children: '天' },
              toText: true,
              children: passed_day.toString(),
            },
            children: { className: 'feature6-text', children: '正常运行' },
          },
          {
            md: 8,
            xs: 24,
            className: 'feature6-number-wrapper',
            name: 'child1',
            number: {
              className: 'feature6-number ',
              id: "site-counter",
              unit: { className: 'feature6-unit', children: '次' },
              toText: true,
              children: '-1',
            },
            children: { className: 'feature6-text', children: '网站访问量' },
          },
          {
            md: 8,
            xs: 24,
            className: 'feature6-number-wrapper',
            name: 'child2',
            number: {
              id: '',
              className: 'feature6-number',
              unit: { className: 'feature6-unit', children: '亿' },
              toText: true,
              children: '2.10',
            },
            children: { className: 'feature6-text', children: '训练样本数量' },
          },
        ],
      },
    ],
  },
};
export const Content50DataSource = {
  wrapper: { className: 'home-page-wrapper content5-wrapper' },
  page: { className: 'home-page content5' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      { name: 'title', children: '一些项目', className: 'title-h1' },
      {
        name: 'content',
        className: 'title-content',
        children: '',
        // children: '在这里用一段话介绍服务的案例情况',
      },
    ],
  },
  block: {
    className: 'content5-img-wrapper',
    gutter: 16,
    children: [
      {
        name: 'block0',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content', href: '/#/gallery' },
          img: {
            children: p1,
            // 'https://t.alipayobjects.com/images/rmsweb/T11aVgXc4eXXXXXXXX.svg',
          },
          content: { children: '时光相册' },
        },
      },
      // {
      //   name: 'block1',
      //   className: 'block',
      //   md: 6,
      //   xs: 24,
      //   children: {
      //     wrapper: { className: 'content5-block-content' },
      //     img: {
      //       children:
      //         'https://zos.alipayobjects.com/rmsportal/faKjZtrmIbwJvVR.svg',
      //     },
      //     content: { children: 'Ant Motion' },
      //   },
      // },
      // {
      //   name: 'block2',
      //   className: 'block',
      //   md: 6,
      //   xs: 24,
      //   children: {
      //     wrapper: { className: 'content5-block-content' },
      //     img: {
      //       children:
      //         'https://t.alipayobjects.com/images/rmsweb/T11aVgXc4eXXXXXXXX.svg',
      //     },
      //     content: { children: 'Ant Design' },
      //   },
      // },
      // {
      //   name: 'block3',
      //   className: 'block',
      //   md: 6,
      //   xs: 24,
      //   children: {
      //     wrapper: { className: 'content5-block-content' },
      //     img: {
      //       children:
      //         'https://zos.alipayobjects.com/rmsportal/faKjZtrmIbwJvVR.svg',
      //     },
      //     content: { children: 'Ant Motion' },
      //   },
      // },
      // {
      //   name: 'block4',
      //   className: 'block',
      //   md: 6,
      //   xs: 24,
      //   children: {
      //     wrapper: { className: 'content5-block-content' },
      //     img: {
      //       children:
      //         'https://t.alipayobjects.com/images/rmsweb/T11aVgXc4eXXXXXXXX.svg',
      //     },
      //     content: { children: 'Ant Design' },
      //   },
      // },
      // {
      //   name: 'block5',
      //   className: 'block',
      //   md: 6,
      //   xs: 24,
      //   children: {
      //     wrapper: { className: 'content5-block-content' },
      //     img: {
      //       children:
      //         'https://zos.alipayobjects.com/rmsportal/faKjZtrmIbwJvVR.svg',
      //     },
      //     content: { children: 'Ant Motion' },
      //   },
      // },
      // {
      //   name: 'block6',
      //   className: 'block',
      //   md: 6,
      //   xs: 24,
      //   children: {
      //     wrapper: { className: 'content5-block-content' },
      //     img: {
      //       children:
      //         'https://t.alipayobjects.com/images/rmsweb/T11aVgXc4eXXXXXXXX.svg',
      //     },
      //     content: { children: 'Ant Design' },
      //   },
      // },
      {
        name: 'block7',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children: p8
            // 'https://zos.alipayobjects.com/rmsportal/faKjZtrmIbwJvVR.svg',
          },
          content: { children: '即将登场' },
        },
      },
      {
        name: '8',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children: p8
            // 'https://zos.alipayobjects.com/rmsportal/faKjZtrmIbwJvVR.svg',
          },
          content: { children: '即将登场' },
        },
      },
      {
        name: 'block9',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children: p8
            // 'https://zos.alipayobjects.com/rmsportal/faKjZtrmIbwJvVR.svg',
          },
          content: { children: '即将登场' },
        },
      },
    ],
  },
};

var date = new Date();
const year = date.getFullYear(); //获取完整的年份(4位)
export const Footer11DataSource = {
  wrapper: { className: 'home-page-wrapper footer1-wrapper' },
  OverPack: { className: 'footer1', playScale: 0.2 },
  block: {
    className: 'home-page',
    gutter: 0,
    children: [
      {
        name: 'block0',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          className: 'logo',
          children: logo,
          isimg: 'true',

          // children:
          //   'https://zos.alipayobjects.com/rmsportal/qqaimmXZVSwAhpL.svg',
        },
        childWrapper: {
          className: 'slogan',
          children: [
            {
              name: 'content0',
              children: '',
            },
          ],
        },
      },
      {
        name: 'block1',
        xs: 24,
        md: 6,
        className: 'block',
        title: { isimg: 'false', children: '联系我' },
        childWrapper: {
          children: [
            { name: 'link0', href: 'https://github.com/Andision', children: 'Github' },
            { name: 'link1', href: 'mailto:andision.zhang@qq.com', children: 'Email' },
            { name: 'link2', href: 'https://y.music.163.com/m/user?id=356838641', children: '网易云音乐' },
            { name: 'link3', href: '#', children: '参考指南' },
          ],
        },
      },
      {
        name: 'block2',
        xs: 24,
        md: 6,
        className: 'block',
        title: { isimg: 'false', children: '关于' },
        childWrapper: {
          children: [
            { href: '#', name: 'link0', children: 'FAQ' },
            { href: '#', name: 'link1', children: '联系我们' },
          ],
        },
      },
      {
        name: 'block3',
        xs: 24,
        md: 6,
        className: 'block',
        title: { isimg: 'false', children: '资源' },
        childWrapper: {
          children: [
            { href: '#', name: 'link0', children: 'Ant Design' },
            { href: '#', name: 'link1', children: 'Ant Motion' },
          ],
        },
      },
    ],
  },
  copyrightWrapper: { className: 'copyright-wrapper' },
  copyrightPage: { className: 'home-page' },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        ©2020-{year} Andision Zhang. All Rights Reserved.
      </span>
    ),
  },
};
