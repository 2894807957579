import React from 'react';
// import { Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import QueueAnim from 'rc-queue-anim';
import TweenOne from 'rc-tween-one';
// import { isImg } from './utils';

class Banner extends React.PureComponent {
  constructor(){
    super();
    this.state={

    }
  }
  componentDidMount() {
    const jinrishici = require('jinrishici');
    jinrishici.load(result => {
      // console.log(result.data.content);
      this.setState({
        gushi: result.data.content
      })
    }, errData => {
      // console.log(errData);
      this.setState({
        gushi: '今日诗词加载中......'
      })
    });


  }
  render() {
    const { ...currentProps } = this.props;
    const { dataSource } = currentProps;
    delete currentProps.dataSource;
    delete currentProps.isMobile;
    return (
      <div {...currentProps} {...dataSource.wrapper}>
        <QueueAnim
          key="QueueAnim"
          type={['bottom', 'top']}
          delay={200}
          {...dataSource.textWrapper}
        >
          <div key="title" {...dataSource.title}>
            {/* {typeof dataSource.title.children === 'string' &&
            dataSource.title.children.match(isImg) ? (
              <img src={dataSource.title.children} width="100%" alt="img" />
            ) : (
              dataSource.title.children
            )} */}
            <img src={dataSource.title.children} width="100%" alt="img" />
          </div>
          <div key="content" {...dataSource.content}>
            {dataSource.content.children}
          </div>
          {/* <a className="jinrishici" href='https://www.jinrishici.com'>{this.state.gushi}</a> */}
          {/* <Button ghost key="button" {...dataSource.button}>
            {dataSource.button.children}
          </Button> */}
        </QueueAnim>
        <TweenOne
          animation={{
            y: '-=20',
            yoyo: true,
            repeat: -1,
            duration: 1000,
          }}
          className="banner0-icon"
          key="icon"
        >
          <DownOutlined />
        </TweenOne>
      </div>
    );
  }
}
export default Banner;
