import React, { Fragment } from 'react';
import { Card, List, ConfigProvider, theme } from 'antd';
import Icon from '@ant-design/icons';
import "./Collection.less";

const { Meta } = Card;
const defaultPicture = () => { return (<svg t="1682421011889" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="18137" width="200" height="200"><path d="M932 302.016v240H602.016V512a60.032 60.032 0 0 0-60-60h-60c-33.12 0-60 26.88-60 60v30.016H92v-240h840z m-329.984 300v30.016a60.064 60.064 0 0 1-60 60h-60a60.032 60.032 0 0 1-60-60v-30.016H92v240h840v-240H602.016z" fill="#EE4646" p-id="18138"></path><path d="M992 302.016v540a60.064 60.064 0 0 1-60 60H92A60.032 60.032 0 0 1 32 842.016V302.016c0-33.12 26.88-60 60-60H272v60H92v240h330.016v60H92v240h840v-240H602.016v-60h330.016v-240H752V242.016h180c33.088 0 60 26.88 60 60z m-300-60h-360v60h360V242.016z" fill="#BA3333" p-id="18139"></path><path d="M692 122.016h-360c-33.12 0-60 26.88-60 60v120.032h60V182.016h360v120.032H752V182.016a60.032 60.032 0 0 0-60-60zM316.992 287.008h-32v-32h32v32z m420 0h-32v-32h32v32z" fill="#454545" p-id="18140"></path><path d="M542.016 452h-60c-33.12 0-60 26.88-60 60V632.032c0 33.088 26.88 60 60 60h60a60.064 60.064 0 0 0 60-60V512a60.032 60.032 0 0 0-60-60z m0 180h-60V512h60v120zM284.992 255.008h32v32h-32v-32z m420 0h32v32h-32v-32z" fill="#DDDDDD" p-id="18141"></path></svg>) }
const data = [
    {
        title: '10分钟邮箱',
        description: '确保隐私安全的一次性随机邮箱',
        href: 'http://10minutemail.org',
        picture: () => { return (<svg t="1682420662327" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5860" width="200" height="200"><path d="M848.76288 333.62432H164.99712C99.32288 333.62432 46.08 386.87232 46.08 452.54144v297.28768c0 65.67424 53.24288 118.92224 118.91712 118.92224h683.77088c65.66912 0 118.91712-53.24288 118.91712-118.92224V452.54144c-0.00512-65.66912-53.248-118.91712-118.92224-118.91712z" fill="#96383D" p-id="5861"></path><path d="M639.8208 51.2h-474.8288a44.58496 44.58496 0 0 0-44.59008 44.59008v609.44896a44.57984 44.57984 0 0 0 44.59008 44.59008h683.776a44.58496 44.58496 0 0 0 44.59008-44.59008V304.73728L639.8208 51.2z" fill="#EBE2CE" p-id="5862"></path><path d="M551.4752 229.57568H209.59232v44.59008h341.88288v-44.59008zM209.59232 794.42432h594.58048v-44.5952H209.59232v44.5952z m0-89.18528h594.58048v-44.5952H209.59232v44.5952z m0-178.37568h594.58048v-44.5952H209.59232v44.5952z m0 89.18528h594.58048v-44.59008H209.59232v44.59008z m0-222.96576v44.59008h594.58048v-44.59008H209.59232z" fill="#C9C1B1" p-id="5863"></path><path d="M941.83936 393.31328L75.60704 955.02848c12.89216 10.93632 29.29664 17.77152 47.44192 17.77152H893.5936c40.91904 0 74.09152-33.4592 74.09152-74.74688V449.60768c-0.00512-22.58432-10.14784-42.58816-25.84576-56.2944z" fill="#D54D54" p-id="5864"></path><path d="M71.99232 396.5696C56.25344 410.18368 46.08 430.08512 46.08 452.54144v445.93152C46.08 939.53024 79.34976 972.8 120.40192 972.8h772.95104c18.20672 0 34.65216-6.79424 47.56992-17.664L71.99232 396.5696z" fill="#EA5455" p-id="5865"></path><path d="M655.52384 66.90816v236.8l237.82912 74.89024V304.73728z" fill="" p-id="5866"></path><path d="M640.66048 52.0448v207.2576a44.58496 44.58496 0 0 0 44.5952 44.5952h207.2576l-251.8528-251.8528z" fill="#FFFBF2" p-id="5867"></path></svg>) }
    },
    {
        title: '10分钟邮箱',
        description: '确保隐私安全的一次性随机邮箱',
        href: 'http://10minutemail.org',
        picture: () => { return (<svg t="1682420662327" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5860" width="200" height="200"><path d="M848.76288 333.62432H164.99712C99.32288 333.62432 46.08 386.87232 46.08 452.54144v297.28768c0 65.67424 53.24288 118.92224 118.91712 118.92224h683.77088c65.66912 0 118.91712-53.24288 118.91712-118.92224V452.54144c-0.00512-65.66912-53.248-118.91712-118.92224-118.91712z" fill="#96383D" p-id="5861"></path><path d="M639.8208 51.2h-474.8288a44.58496 44.58496 0 0 0-44.59008 44.59008v609.44896a44.57984 44.57984 0 0 0 44.59008 44.59008h683.776a44.58496 44.58496 0 0 0 44.59008-44.59008V304.73728L639.8208 51.2z" fill="#EBE2CE" p-id="5862"></path><path d="M551.4752 229.57568H209.59232v44.59008h341.88288v-44.59008zM209.59232 794.42432h594.58048v-44.5952H209.59232v44.5952z m0-89.18528h594.58048v-44.5952H209.59232v44.5952z m0-178.37568h594.58048v-44.5952H209.59232v44.5952z m0 89.18528h594.58048v-44.59008H209.59232v44.59008z m0-222.96576v44.59008h594.58048v-44.59008H209.59232z" fill="#C9C1B1" p-id="5863"></path><path d="M941.83936 393.31328L75.60704 955.02848c12.89216 10.93632 29.29664 17.77152 47.44192 17.77152H893.5936c40.91904 0 74.09152-33.4592 74.09152-74.74688V449.60768c-0.00512-22.58432-10.14784-42.58816-25.84576-56.2944z" fill="#D54D54" p-id="5864"></path><path d="M71.99232 396.5696C56.25344 410.18368 46.08 430.08512 46.08 452.54144v445.93152C46.08 939.53024 79.34976 972.8 120.40192 972.8h772.95104c18.20672 0 34.65216-6.79424 47.56992-17.664L71.99232 396.5696z" fill="#EA5455" p-id="5865"></path><path d="M655.52384 66.90816v236.8l237.82912 74.89024V304.73728z" fill="" p-id="5866"></path><path d="M640.66048 52.0448v207.2576a44.58496 44.58496 0 0 0 44.5952 44.5952h207.2576l-251.8528-251.8528z" fill="#FFFBF2" p-id="5867"></path></svg>) }
    },
    {
        title: '10分钟邮箱',
        description: '确保隐私安全的一次性随机邮箱',
        href: 'http://10minutemail.org',
        picture: () => { return (<svg t="1682420662327" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5860" width="200" height="200"><path d="M848.76288 333.62432H164.99712C99.32288 333.62432 46.08 386.87232 46.08 452.54144v297.28768c0 65.67424 53.24288 118.92224 118.91712 118.92224h683.77088c65.66912 0 118.91712-53.24288 118.91712-118.92224V452.54144c-0.00512-65.66912-53.248-118.91712-118.92224-118.91712z" fill="#96383D" p-id="5861"></path><path d="M639.8208 51.2h-474.8288a44.58496 44.58496 0 0 0-44.59008 44.59008v609.44896a44.57984 44.57984 0 0 0 44.59008 44.59008h683.776a44.58496 44.58496 0 0 0 44.59008-44.59008V304.73728L639.8208 51.2z" fill="#EBE2CE" p-id="5862"></path><path d="M551.4752 229.57568H209.59232v44.59008h341.88288v-44.59008zM209.59232 794.42432h594.58048v-44.5952H209.59232v44.5952z m0-89.18528h594.58048v-44.5952H209.59232v44.5952z m0-178.37568h594.58048v-44.5952H209.59232v44.5952z m0 89.18528h594.58048v-44.59008H209.59232v44.59008z m0-222.96576v44.59008h594.58048v-44.59008H209.59232z" fill="#C9C1B1" p-id="5863"></path><path d="M941.83936 393.31328L75.60704 955.02848c12.89216 10.93632 29.29664 17.77152 47.44192 17.77152H893.5936c40.91904 0 74.09152-33.4592 74.09152-74.74688V449.60768c-0.00512-22.58432-10.14784-42.58816-25.84576-56.2944z" fill="#D54D54" p-id="5864"></path><path d="M71.99232 396.5696C56.25344 410.18368 46.08 430.08512 46.08 452.54144v445.93152C46.08 939.53024 79.34976 972.8 120.40192 972.8h772.95104c18.20672 0 34.65216-6.79424 47.56992-17.664L71.99232 396.5696z" fill="#EA5455" p-id="5865"></path><path d="M655.52384 66.90816v236.8l237.82912 74.89024V304.73728z" fill="" p-id="5866"></path><path d="M640.66048 52.0448v207.2576a44.58496 44.58496 0 0 0 44.5952 44.5952h207.2576l-251.8528-251.8528z" fill="#FFFBF2" p-id="5867"></path></svg>) }
    },
    {
        title: '10分钟邮箱',
        description: '确保隐私安全的一次性随机邮箱',
        href: 'http://10minutemail.org',
        picture: () => { return (<svg t="1682420662327" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5860" width="200" height="200"><path d="M848.76288 333.62432H164.99712C99.32288 333.62432 46.08 386.87232 46.08 452.54144v297.28768c0 65.67424 53.24288 118.92224 118.91712 118.92224h683.77088c65.66912 0 118.91712-53.24288 118.91712-118.92224V452.54144c-0.00512-65.66912-53.248-118.91712-118.92224-118.91712z" fill="#96383D" p-id="5861"></path><path d="M639.8208 51.2h-474.8288a44.58496 44.58496 0 0 0-44.59008 44.59008v609.44896a44.57984 44.57984 0 0 0 44.59008 44.59008h683.776a44.58496 44.58496 0 0 0 44.59008-44.59008V304.73728L639.8208 51.2z" fill="#EBE2CE" p-id="5862"></path><path d="M551.4752 229.57568H209.59232v44.59008h341.88288v-44.59008zM209.59232 794.42432h594.58048v-44.5952H209.59232v44.5952z m0-89.18528h594.58048v-44.5952H209.59232v44.5952z m0-178.37568h594.58048v-44.5952H209.59232v44.5952z m0 89.18528h594.58048v-44.59008H209.59232v44.59008z m0-222.96576v44.59008h594.58048v-44.59008H209.59232z" fill="#C9C1B1" p-id="5863"></path><path d="M941.83936 393.31328L75.60704 955.02848c12.89216 10.93632 29.29664 17.77152 47.44192 17.77152H893.5936c40.91904 0 74.09152-33.4592 74.09152-74.74688V449.60768c-0.00512-22.58432-10.14784-42.58816-25.84576-56.2944z" fill="#D54D54" p-id="5864"></path><path d="M71.99232 396.5696C56.25344 410.18368 46.08 430.08512 46.08 452.54144v445.93152C46.08 939.53024 79.34976 972.8 120.40192 972.8h772.95104c18.20672 0 34.65216-6.79424 47.56992-17.664L71.99232 396.5696z" fill="#EA5455" p-id="5865"></path><path d="M655.52384 66.90816v236.8l237.82912 74.89024V304.73728z" fill="" p-id="5866"></path><path d="M640.66048 52.0448v207.2576a44.58496 44.58496 0 0 0 44.5952 44.5952h207.2576l-251.8528-251.8528z" fill="#FFFBF2" p-id="5867"></path></svg>) }
    },
    {
        title: '10分钟邮箱',
        description: '确保隐私安全的一次性随机邮箱',
        href: 'http://10minutemail.org',
        picture: () => { return (<svg t="1682420662327" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5860" width="200" height="200"><path d="M848.76288 333.62432H164.99712C99.32288 333.62432 46.08 386.87232 46.08 452.54144v297.28768c0 65.67424 53.24288 118.92224 118.91712 118.92224h683.77088c65.66912 0 118.91712-53.24288 118.91712-118.92224V452.54144c-0.00512-65.66912-53.248-118.91712-118.92224-118.91712z" fill="#96383D" p-id="5861"></path><path d="M639.8208 51.2h-474.8288a44.58496 44.58496 0 0 0-44.59008 44.59008v609.44896a44.57984 44.57984 0 0 0 44.59008 44.59008h683.776a44.58496 44.58496 0 0 0 44.59008-44.59008V304.73728L639.8208 51.2z" fill="#EBE2CE" p-id="5862"></path><path d="M551.4752 229.57568H209.59232v44.59008h341.88288v-44.59008zM209.59232 794.42432h594.58048v-44.5952H209.59232v44.5952z m0-89.18528h594.58048v-44.5952H209.59232v44.5952z m0-178.37568h594.58048v-44.5952H209.59232v44.5952z m0 89.18528h594.58048v-44.59008H209.59232v44.59008z m0-222.96576v44.59008h594.58048v-44.59008H209.59232z" fill="#C9C1B1" p-id="5863"></path><path d="M941.83936 393.31328L75.60704 955.02848c12.89216 10.93632 29.29664 17.77152 47.44192 17.77152H893.5936c40.91904 0 74.09152-33.4592 74.09152-74.74688V449.60768c-0.00512-22.58432-10.14784-42.58816-25.84576-56.2944z" fill="#D54D54" p-id="5864"></path><path d="M71.99232 396.5696C56.25344 410.18368 46.08 430.08512 46.08 452.54144v445.93152C46.08 939.53024 79.34976 972.8 120.40192 972.8h772.95104c18.20672 0 34.65216-6.79424 47.56992-17.664L71.99232 396.5696z" fill="#EA5455" p-id="5865"></path><path d="M655.52384 66.90816v236.8l237.82912 74.89024V304.73728z" fill="" p-id="5866"></path><path d="M640.66048 52.0448v207.2576a44.58496 44.58496 0 0 0 44.5952 44.5952h207.2576l-251.8528-251.8528z" fill="#FFFBF2" p-id="5867"></path></svg>) }
    },

];

export default class Collection extends React.Component {

    render() {
        return (
            <Fragment>
                <div className='main-component'>
                    <ConfigProvider
                        theme={{
                            algorithm: theme.darkAlgorithm,
                        }}
                    >
                        <List
                            grid={{
                                gutter: 16, xs: 1, sm: 2, md: 3, lg: 4, xl: 4, xxl: 4
                            }}
                            dataSource={data}
                            renderItem={(item) => (
                                <List.Item className='tools-collection-item-card-a'>
                                    <a target="_blank" href={item.href} rel="noopener noreferrer">
                                        <Card
                                            hoverable
                                        // style={{ width: 100 % }}
                                        >
                                            <span className='tools-collection-item-card-picture'><Icon component={item.picture === undefined ? defaultPicture : item.picture} /></span>
                                            <Meta title={item.title} description={item.description} />
                                        </Card>
                                    </a>
                                </List.Item>
                            )}
                        />

                    </ConfigProvider>

                </div>
            </Fragment>
        )

    }
}
