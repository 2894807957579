import React from 'react';
import LightGallery from 'lightgallery/react';

// import { Row, Col } from 'antd';

// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import 'lightgallery/css/lg-autoplay.css'
// import 'lightgallery/css/lg-comments.css'
import 'lightgallery/css/lg-fullscreen.css'
// import 'lightgallery/css/lg-pager.css'
import 'lightgallery/css/lg-rotate.css'
// import 'lightgallery/css/lg-share.css'
// import 'lightgallery/css/lg-video.css'



// If you want you can use SCSS instead of css
// import 'lightgallery/scss/lightgallery.scss';
// import 'lightgallery/scss/lg-zoom.scss';

// import plugins if you need
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import lgAutoplay from 'lightgallery/plugins/autoplay';
// import lgComment from 'lightgallery/plugins/comment';
import lgFullscreen from 'lightgallery/plugins/fullscreen';
// import lgHash from 'lightgallery/plugins/hash';
// import lgPager from 'lightgallery/plugins/pager';
import lgRotate from 'lightgallery/plugins/rotate';
// import lgShare from 'lightgallery/plugins/share';
// import lgVideo from 'lightgallery/plugins/video';

import data from './gallery.json'
import './index.css'

const pic_list = data.pics;

function Gallery() {
    const onInit = () => {
        console.log('lightGallery has been initialized');
    };
    return (
        <div className='bg'>
            <LightGallery
                onInit={onInit}
                speed={500}
                plugins={[lgThumbnail, lgZoom, lgAutoplay,lgFullscreen,lgRotate]}
            // , lgComment,  , lgHash, lgPager, , lgShare, lgVideo, lgMediumZoom
            >
                {/* <a href="img/img1.jpg">
                    <img alt="img1" src="https://zos.alipayobjects.com/rmsportal/HZgzhugQZkqUwBVeNyfz.jpg" />
                </a>
                <a href="img/img2.jpg">
                    <img alt="img2" src="https://zos.alipayobjects.com/rmsportal/HZgzhugQZkqUwBVeNyfz.jpg" />
                </a> */}

                {/* ... */}

                {
                    pic_list.map((item, index) => {
                        // return <li key={index}>{item}</li>
                        // return <img alt={"img"+index} src={item} width="250"/>
                        return (
                            <div
                                // data-lg-size="1406-1390"
                                className="gallery-item"
                                data-src={item}
                                data-sub-html="Photo by Andision. All Rights Reserved."
                                // data-src="https://images.unsplash.com/photo-1581894158358-5ecd2c518883?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1406&q=80"
                                // data-sub-html="<h4>Photo by - <a href='https://unsplash.com/@entrycube' >Diego Guzmán </a></h4> <p> Location - <a href='https://unsplash.com/s/photos/fushimi-inari-taisha-shrine-senbontorii%2C-68%E7%95%AA%E5%9C%B0-fukakusa-yabunouchicho%2C-fushimi-ward%2C-kyoto%2C-japan'>Fushimi Ward, Kyoto, Japan</a></p>"
                            >
                                <img
                                    alt='alt'
                                    className="gallery-img"
                                    src={item.slice(0, -3) + 'md.jpg'}
                                    width="100%"
                                // height="100%"
                                // src="https://images.unsplash.com/photo-1581894158358-5ecd2c518883?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=240&q=80"
                                />
                            </div>
                        )
                    })
                }
            </LightGallery>
        </div>
    );
}

export default Gallery;
