import React from 'react';

const App = () => {
  return (
    <div>
      Hi!
    </div>
  );
};
export default App;
